<template>
  <PageHeaderLayout>
    <div class="main-page-content">
      <ApeMenu :menus="menus" />
    </div>
  </PageHeaderLayout>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'SMenu',
  components: {
    PageHeaderLayout,
  },

  data() {
    return {
      menus: [
        {
          icon: 'dangqi',
          display_name: '档期管理',
          url: '/s_menus/artist_schedule',
          permission_name: 'schedule_list',
        },
        {
          icon: 'dangqi1',
          display_name: '商务管理',
          url: '/s_menus/business_manage',
          permission_name: 'business_manage_list',
        },
        {
          icon: 'paiqi',
          display_name: '工作排期',
          url: '/s_menus/task_schedule',
          permission_name: 'work-schedule-list'
        },
        { icon: 'paiqi', display_name: '工作管理', url: '/work_manage', permission_name: 'work_manage_list' },
        // { icon: 'icon-yingshou', display_name: '工作档期', url: '/s_menus/work_manage', permission_name: 'business_manage_list' },

      ],
    }
  },
}
</script>

<style scoped></style>
